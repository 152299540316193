import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import {ToastContainer, toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';

// const BackendURL = process.env.REACT_APP_BACKEND_ADDRESS;

const EnrollForm = (props) => {

    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_ADDRESS;

    const [inpval, setInpval] = useState({
        fname: "",
        email: "",
        phone: "+91",
        collagename: "",
        branchname: "",
        currentsemester: "",
        scheduleDate: "",
        scheduleDateOther: "",
        duration: "",
        course: ""
    })

    // console.log(inpval)

    const setval = async (e)=>{
        const {name, value} = e.target;

        setInpval(()=>{
            return{
                ...inpval,
                [name]: value
            }
        })

    }

    const saveData = async (e)=>{
        e.preventDefault();
        const {fname, email, phone, collagename, branchname, currentsemester, course, scheduleDate, scheduleDateOther, duration} = inpval;
        console.log(inpval)
        if(!fname || !email || !phone || !collagename || !branchname || !currentsemester || !course || !duration){
            toast.error('Please Fill all Fields');
        }else{
            try {
                // console.log(BackendURL)
                const data = await fetch(`${BACKEND_URL}/enrollstudent`,{
                    method: "POST",
                    headers:{
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        fname, email, phone, collagename, branchname, currentsemester, scheduleDate, scheduleDateOther, duration, course
                    })
                })

                const res = await data.json();
                console.log(data)

                if(res.status === 201){
                    setInpval({
                        ...inpval,
                        fname: "",
                        email: "",
                        phone: "+91 ",
                        collagename: "",
                        branchname: "",
                        currentsemester: "",
                        course: "",
                        scheduleDate: "",
                        scheduleDateOther: "",
                        duration: ""
                    })
                    toast.success('Register Successfully');
                }
                if(res.status === 409){
                    toast.error("This Email allready register")
                }

            } catch (error) {
                console.error("Error fetching data:", error);       
            }
        }
        
    }


    return (
        <>
            <div className={`PopUpmodal`} id="MainParentPopup">
                <div className="modalPopUpcontent">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">
                            Apply Now for Summer Internship Program
                        </h4>
                        <button
                            type="button"
                            className="close close-button"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={props.closeModel}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="w3l-contacts-12 home_form">
                            <div className="contacts12-main">
                                <form className="main-input">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        name="fname"
                                        value={inpval.fname}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={inpval.email}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Whatsapp Number"
                                        name="phone"
                                        value={inpval.phone}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="College Name"
                                        name="collagename"
                                        value={inpval.collagename}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Branch Name"
                                        name="branchname"
                                        value={inpval.branchname}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Current Semester"
                                        name="currentsemester"
                                        value={inpval.currentsemester}
                                        required
                                        onChange={setval}
                                    />
                                    <div className="serSel">
                                        <select name='scheduleDate' value={inpval.scheduleDate} onChange={setval}>
                                            <option value="Null">Tentative Training Schedule</option>
                                            <option value="15th May 2025">15th May, 2025</option>
                                            <option value="05th June 2025">05th June, 2025</option>
                                            <option value="10th june 2025">01th July, 2025</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    {inpval.scheduleDate === "other" && (
                                        <input
                                            type="text"
                                            placeholder="Enter Custom Training Schedule"
                                            name="scheduleDateOther"
                                            value={inpval.scheduleDateOther}
                                            onChange={setval}
                                        />
                                    )}
                                    <div className="serSel">
                                        <select name='duration' value={inpval.duration} onChange={setval}>
                                            <option value="Null">Duration</option>
                                            <option value="4weeks">4 Weeks</option>
                                            <option value="6weeks">6 Weeks</option>
                                            <option value="8weeks">8 Weeks</option>
                                        </select>
                                    </div>
                                    <div className="serSel">
                                        <select name='course' className='mb-0' value={inpval.course} onChange={setval}>
                                            <option value="Null">Applying For</option>
                                            <option value="Full Stack Development">Full Stack Web Development</option>
                                            <option value="Machine Learning & AI">Data Science with Machine Learning & AI</option>
                                            <option value="Cloud Engineering & DevOps">Cloud Engineering & DevOps</option>
                                            <option value="Embedded Systems & IoT">Embedded Systems & IoT</option>
                                        </select>
                                    </div>
                                    <div className="mt-3 mt-md-3">
                                        <button type="submit" onClick={saveData} className="YellowBTN">
                                            Submit Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
            <Toaster />
        </>
    )
}

export default EnrollForm;