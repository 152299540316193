import React, {useState} from 'react';
import EnrollForm from './EnrollForm';

const BenefitSummer = () => {

    const [IsModelOpen, setIsModelOpen] = useState(false)

    const FormOpenBTN = () =>{
        setIsModelOpen(true)
    }

    const closeModel = ()=>{
        setIsModelOpen(false)
    }
    return (
        <>
            <section className="BenefitSummer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 class="headings text-center text-white">Benefits of Summer Program 2025</h2>
                            <p className="subHeading">LEARN WITH ENGINEERING STUDENTS FROM ACROSS INDIA</p>
                        </div>
                    </div>
                    <div className="row BenefitMainDIv">
                        <div className="col-12">
                            <ul className="BenefitUL">
                                <li>
                                    <h6>Practical Experience</h6>
                                    <p>Working on real projects allows learners to apply their skills in a practical setting and gain a better understanding of how technology is used in the industry.</p>
                                </li>
                                <li>
                                    <h6>Skill Development</h6>
                                    <p> We offer opportunities to enhance technical skills and acquire new ones.</p>
                                </li>
                                <li>
                                    <h6>Industry Oriented Content</h6>
                                    <p>We Povide insights into the latest trends and innovations in the tech industry. Explore emerging technologies.</p>
                                </li>
                                <li>
                                    <h6>Resume Enhancement</h6>
                                    <p>Having a tech internship on your resume demonstrates to future employers that can make you stand out among other candidates and increase your chances of landing a full-time job after graduation.</p>
                                </li>
                                <li>
                                    <h6>Project Management Skills</h6>
                                    <p>Internships often involve working on projects with deadlines. This experience helps interns develop project management skills, learn how to prioritize tasks, & understand the importance of meeting deadlines in a professional environment.</p>
                                </li>
                                <li>
                                    <h6>Industry Exposure</h6>
                                    <p>We allow individuals to gain insights into the tech industry's inner workings. This exposure helps interns understand the dynamics of the field, emerging trends, and the challenges faced by technology companies.</p>
                                </li>
                            </ul>
                            <button class="YellowBTN mt-4 m-auto d-flex justify-content-center" onClick={FormOpenBTN}>Enroll Now</button>
                        </div>
                    </div>
                </div>
            </section>
            {IsModelOpen && <EnrollForm closeModel={closeModel}/>}
        </>
    )
}

export default BenefitSummer