import React from "react";

const FAQ = ({ FAQ }) => {
  return (
    <>
      <section className={`faq-section ${FAQ}`}>
        <div className="container">
          <div className="row">
            {/* ***** FAQ Start ***** */}
            <div className="col-md-6 offset-md-3">
              <div className="text-center">
                <h2 className="headings">FAQs</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="faq" id="accordion">
                <div className="row">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample1"
                    >
                        <div className="row">
                  <div className="col-md-6 col-12">
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            What will be the training mode?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            The training mode options are Offline or Online -
                            Live Instructor Led Training.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            What is the selection criterion for Summer Training
                            2025?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            We have a limited number of seats for each batch,
                            and once the required number of seats is filled,
                            registration for the respective batch will be
                            closed. Therefore, students registering first will
                            be allocated the available seats.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            What are the modes of payment for summer internship
                            and training?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            The preferable modes of payment include UPI,
                            Credit/Debit card.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            Can I switch training from offline to online or from
                            online to offline?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Certainly. After confirming your registration, you
                            have the flexibility to switch between Online and
                            Offline modes. However, please note that you will
                            need to pay the differential fees accordingly.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                          >
                            What is the duration of the Summer
                            internship/Training program?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            The training spans 45 days, whether conducted
                            Offline or Online, covering the course curriculum
                            published online. The duration may also vary based
                            on your college/university requirements.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix"
                            aria-expanded="false"
                            aria-controls="flush-collapseSix"
                          >
                            Is there any accommodation facility available for
                            Offline Training Participants?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Yes, we provide accommodation in nearby PGs with
                            separate options for boys and girls. These
                            accommodations are inclusive of food (Breakfast &
                            Dinner), with costs ranging between Rs. 7,000 to Rs.
                            9,000. The participants are responsible for covering
                            these costs, and PG charges are to be paid directly
                            at the PG. Detailed information will be provided by
                            our team, along with a list of PGs and their
                            details.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeven"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeven"
                          >
                            What are the Fees for the Summer Internship/Training program?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                          Please refer to the respective course for detailed fee information.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEight"
                            aria-expanded="false"
                            aria-controls="flush-collapseEight"
                          >
                            Will we receive a Certificate after completing the
                            program?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Yes, participants will receive two certificates via
                            their registered email addresses upon the completion
                            of the training program – one for the Completion of
                            Internship and the other for Projects.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                    <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseNine"
                            aria-expanded="false"
                            aria-controls="flush-collapseNine"
                          >
                            What technologies will I learn during this period?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseNine"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Participants have the opportunity to learn any
                            technology of their choice from the offered courses.
                            For further details, please refer to the courses
                            listed on the website.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTen"
                            aria-expanded="false"
                            aria-controls="flush-collapseTen"
                          >
                            Will I gain experience on real-time projects?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Yes, participants will have the chance to work on
                            multiple projects, including both internal and
                            real-time projects.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEleven"
                            aria-expanded="false"
                            aria-controls="flush-collapseEleven"
                          >
                            Can we add the certificate to our resume?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEleven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Absolutely. Participants can enhance their resumes
                            by adding the certificates and showcasing the skills
                            acquired during the program.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwelve"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwelve"
                          >
                            Who can Join?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwelve"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            The program is open to anyone from the IT domain,
                            including Btech, MCA, and BCA students. All tech
                            enthusiasts are welcome to join us.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThirteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseThirteen"
                          >
                            What kind of work will I be doing if I am selected?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThirteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            Selected interns will undertake an independent
                            technical project based on their chosen technology,
                            in addition to other business-driven tasks. A
                            supportive work environment will be provided to
                            facilitate skill development.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFourteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFourteen"
                          >
                            How do I know if this Internship/Training is
                            suitable for me?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFourteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                            If you are a Tech Enthusiast or wish to explore and
                            deepen your technical skills, Upflairs is the ideal
                            place for you. The program offers the opportunity to
                            choose your preferred technology and gain practical
                            experience while working with the business team,
                            challenging your creativity to excel.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item faqcard">
                        <h2 className="accordion-header faqHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFifteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFifteen"
                          >
                            What is the refund policy?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFifteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="accordion-body">
                          Once the fee is submitted, it is non-refundable under any circumstances. We recommend discussing all terms and conditions with the employer before registration.
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
