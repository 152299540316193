import React, {useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';

const LinuxForm = (props) => {

    const BACKEND_URL = process.env.REACT_APP_BACKEND_ADDRESS;

    const [inpval, setInpval] = useState({
        fname: "",
        email: "",
        mobileNum: "+91",
        collageName: "",
        branchName: "",
        currentSeme: "",
        tentativeTraining: "",
        duration: ""
    })

    
    const setval = (e)=>{
        const {name, value} = e.target;

        setInpval(()=>{
            return{
                ...inpval,
                [name]: value
            }
        })
    }

    const saveData = async (e)=>{
        e.preventDefault();
        const {fname, email, mobileNum, collageName, branchName, currentSeme, tentativeTraining, duration} = inpval;
        console.log(inpval)
        if(!fname || !email || !mobileNum || !collageName || !branchName || !currentSeme || !tentativeTraining || !duration){
            toast.error('Please Fill all Fields');
        }else{
            try {
                // console.log(BackendURL)
                const data = await fetch(`${BACKEND_URL}/linuxmodel`,{
                    method: "POST",
                    headers:{
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        fname, email, mobileNum, collageName, branchName, currentSeme, tentativeTraining, duration
                    })
                })

                const res = await data.json();
                console.log(data)

                if(res.status === 201){
                    setInpval({
                        ...inpval,
                        fname: "",
                        email: "",
                        mobileNum: "+91",
                        collageName: "",
                        branchName: "",
                        currentSeme: "",
                        tentativeTraining: "",
                        duration: ""
                    })
                    toast.success('Register Successfully');
                }
                if(res.status === 409){
                    toast.error("This Email allready register")
                }

            } catch (error) {
                console.error("Error fetching data:", error);       
            }
        }
    }

  return (
    <>
        <div className={`PopUpmodal`} id="MainParentPopup">
                <div className="modalPopUpcontent">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">
                            Apply Now for Linux
                        </h4>
                        <button
                            type="button"
                            className="close close-button"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={props.closeModel}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="w3l-contacts-12 home_form">
                            <div className="contacts12-main">
                                <form className="main-input">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        name="fname"
                                        value={inpval.fname}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={inpval.email}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Whatsapp Number"
                                        name="mobileNum"
                                        value={inpval.mobileNum}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="College Name"
                                        name="collageName"
                                        value={inpval.collageName}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Branch Name"
                                        name="branchName"
                                        value={inpval.branchName}
                                        required
                                        onChange={setval}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Current Semester"
                                        name="currentSeme"
                                        value={inpval.currentSeme}
                                        required
                                        onChange={setval}
                                    />
                                    <div className="serSel">
                                        <select name='tentativeTraining' value={inpval.tentativeTraining} onChange={setval}>
                                            <option value="Null">Tentative Training Schedule</option>
                                            <option value="15th march 2025">15th March, 2025</option>
                                            {/* <option value="other">Other</option> */}
                                        </select>
                                    </div>
                                    {/* {inpval.scheduleDate === "other" && (
                                        <input
                                            type="text"
                                            placeholder="Enter Custom Training Schedule"
                                            name="scheduleDateOther"
                                            value={inpval.scheduleDateOther}
                                            onChange={setval}
                                        />
                                    )} */}
                                    <div className="serSel">
                                        <select name='duration' value={inpval.duration} onChange={setval}>
                                            <option value="Null">Duration</option>
                                            <option value="6weeks">6 Weeks</option>
                                        </select>
                                    </div>
                                    <div className="mt-3 mt-md-3">
                                        <button type="submit" onClick={saveData} className="YellowBTN">
                                            Submit Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
            <Toaster />
    </>
  )
}

export default LinuxForm